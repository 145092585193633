import styled from "styled-components"

import { useTranslate } from "src/i18n/useTranslate"
import { divider } from "src/ui/colors"
import { CopyBox } from "src/ui/CopyText/CopyBox"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function ApiClientCredentials({
  clientId,
  clientSecret,
  hideBorder,
}: {
  clientId: string
  clientSecret?: string
  hideBorder?: boolean
}) {
  const { t, langKeys } = useTranslate()

  return (
    <CredentialsBox hideBorder={hideBorder}>
      <FormBox>
        <Row>
          <MText variant="subtitle">{t(langKeys.api_clients_client_id)}</MText>
          <CopyBox text={clientId} />
        </Row>

        <Row>
          <MText variant="subtitle">
            {t(langKeys.api_clients_client_secret)}
          </MText>
          {clientSecret ? <CopyBox text={clientSecret} /> : <MSkeleton />}
        </Row>
      </FormBox>
    </CredentialsBox>
  )
}

const CredentialsBox = styled.div<{ hideBorder?: boolean }>`
  margin-bottom: ${spacing.XL};
  padding-bottom: ${spacing.XL};
  border-bottom: ${({ hideBorder }) =>
    hideBorder ? 0 : `1px solid ${divider}`};
`

const FormBox = styled.div`
  display: grid;
  gap: ${spacing.L};
`

const Row = styled.div`
  display: grid;
  gap: ${spacing.XS};
`

import { useState } from "react"
import styled from "styled-components"

import { Pager } from "src/components/Pager/Pager"
import { EmbeddedSettingsProfile } from "src/components/SettingsProfiles/EmbeddedSettingsProfile"
import { HREF_MINUT_HELP_HOME_PROFILES } from "src/constants/hrefs"
import { HOMEPROFILES_LIMIT_LIST } from "src/data/homeProfiles/logic/homeProfileConstants"
import { useFetchProfiles } from "src/data/homeProfiles/queries/HomeProfilesQueries"
import {
  IProfile,
  TProfileState,
} from "src/data/homeProfiles/types/homeProfilesTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHomesCount } from "src/data/organizations/queries/homeQueries"
import { getAccessLogic } from "src/data/user/logic/accessLogic"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButton } from "src/ui/Button/MButton"
import ConfirmDialog from "src/ui/Dialog/ConfirmDialog"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

import { SettingsProfileCard } from "./SettingsProfileCard"

export function SettingsProfiles() {
  const { t, langKeys } = useTranslate()
  const { org } = useOrganization()
  const hasAdminAccess = getAccessLogic({ role: org.user_role }).hasAdminAccess

  const [limit] = useState(HOMEPROFILES_LIMIT_LIST)
  const [offset, setOffset] = useState(0)
  const [cannotRemoveDescr, setCannotRemoveDescr] = useState("")

  const fetchProfiles = useFetchProfiles({
    orgId: org.id,
    filter: { limit, offset },
    options: { staleTime: 60 * 1000 },
  })

  const fetchHomesCount = useFetchHomesCount({
    orgId: org.id,
    options: { staleTime: 100 },
  })

  const homesCount = fetchHomesCount.data ?? 0

  const profiles: TProfileState = fetchProfiles.data?.profiles || []
  const profilesCount = fetchProfiles.data?.paging.total_count || 0
  const isLoading = fetchProfiles.isInitialLoading
  const isError = fetchProfiles.isError

  async function validateRemove(p: IProfile) {
    const containsHomes = !!p.number_of_homes
    if (!containsHomes) {
      return true
    }

    setCannotRemoveDescr(t(langKeys.profiles_cant_remove_homes_exists_body))
    return false
  }

  const showProfileList = profilesCount > 1
  const showAddButton = homesCount > 1 && hasAdminAccess

  let description: React.ReactNode = ""

  if (homesCount > 1 && profilesCount > 1) {
    description = (
      <>
        {t(langKeys.profiles_add_short_description)}{" "}
        <ExternalLink href={HREF_MINUT_HELP_HOME_PROFILES}>
          {t(langKeys.learn_more)}
        </ExternalLink>
      </>
    )
  }

  if (homesCount > 1 && profilesCount === 1) {
    description = (
      <>
        {t(langKeys.profiles_single_profile_footer)}{" "}
        {t(langKeys.profiles_add_short_description)}{" "}
        <ExternalLink href={HREF_MINUT_HELP_HOME_PROFILES}>
          {t(langKeys.learn_more)}
        </ExternalLink>
      </>
    )
  }

  if (isLoading) {
    return (
      <SettingsProfilesWrapper
        description={description}
        showAddButton={showAddButton}
      />
    )
  }

  if (isError) {
    return (
      <SettingsProfilesWrapper
        description={description}
        showAddButton={showAddButton}
      >
        <MBanner type="error">{t(langKeys.failed_general_error_title)}</MBanner>
      </SettingsProfilesWrapper>
    )
  }

  return (
    <SettingsProfilesWrapper
      description={description}
      showAddButton={showAddButton}
    >
      {showProfileList ? (
        <div>
          <CardBox>
            {profiles.map((p) => {
              return (
                <SettingsProfileCard
                  key={p.id}
                  profile={p}
                  removeValidation={validateRemove}
                />
              )
            })}
          </CardBox>
          <PagerWrapper>
            <Pager
              limit={limit}
              offset={offset}
              setOffset={setOffset}
              totalCount={profilesCount}
              scrollOnClick={false}
            />
          </PagerWrapper>
        </div>
      ) : (
        // @ts-expect-error: We don't care aboute noUncheckedIndexedAccess here
        <EmbeddedSettingsProfile initialProfile={profiles[0]} />
      )}

      <ConfirmDialog
        open={!!cannotRemoveDescr}
        title={t(langKeys.profiles_cant_remove_homes_exists_title)}
        description={cannotRemoveDescr}
        onClose={() => setCannotRemoveDescr("")}
        onConfirm={() => setCannotRemoveDescr("")}
      />
    </SettingsProfilesWrapper>
  )
}

function SettingsProfilesWrapper({
  showAddButton = true,
  description,
  children,
}: {
  showAddButton?: boolean
  description?: React.ReactNode
  children?: React.ReactNode
}) {
  const { t } = useTranslate()
  const { navigate } = useRouter()

  function handleAddClick() {
    navigate(Routes.MonitoringSettingAdd.location().pathname)
  }

  return (
    <Box>
      <DescriptionBox>
        <div>
          <MText variant="heading2" marginBottom={spacing.XS2}>
            {t(langKeys.monitoring)}
          </MText>
          <MText variant="body" color="secondary">
            {description}
          </MText>
        </div>

        {showAddButton && (
          <MButton onClick={handleAddClick} size="medium" variant="secondary">
            {t(langKeys.profiles_add_title)}
          </MButton>
        )}
      </DescriptionBox>

      {children}
    </Box>
  )
}

const CardBox = styled.div`
  display: grid;
  max-width: 500px;
  grid-gap: ${spacing.L};
`

const Box = styled.div`
  display: grid;
  gap: ${spacing.XL};
  margin-top: ${spacing.XL};
`

const DescriptionBox = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: space-between;
  align-items: flex-start;
  gap: ${spacing.XL};
`

const PagerWrapper = styled.div`
  margin-top: ${spacing.XL};
`

import styled from "styled-components"

import { breakpoint } from "src/constants/breakpoints"
import { HREF_MINUT_HELP_NOISE_PRESETS } from "src/constants/hrefs"
import { useFlags } from "src/hooks/useFlags"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import IndoorClimateSvg from "src/ui/icons/indoor-climate.svg"
import NoiseMonitoringSvg from "src/ui/icons/noise-monitoring.svg"
import { LearnMore } from "src/ui/Link/LearnMore"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

import { PresetCard } from "./PresetCard"

export function MonitoringPresets() {
  const { t, langKeys } = useTranslate()
  const { debug } = useFlags()

  const title = t(langKeys.preset_monitoring_presets)
  const description = t(
    langKeys.preset_monitoring_presets_description_rental_unit
  )
  return (
    <MonitoringPresetsBox>
      <div>
        <MText variant="heading2" marginBottom={spacing.XS2}>
          {title}
        </MText>
        <MText variant="body" color="secondary">
          {description} <LearnMore href={HREF_MINUT_HELP_NOISE_PRESETS} />
        </MText>
      </div>

      <CardBox>
        <PresetCard
          icon={<NoiseMonitoringSvg width={24} height={24} />}
          title={t(langKeys.presets_noise_monitoring_title)}
          description={t(langKeys.presets_noise_description)}
          target={Routes.SettingsPresetsNoise.location()}
        />

        {debug && (
          <PresetCard
            icon={<IndoorClimateSvg width={24} height={24} />}
            title={t(langKeys.indoor_climate)}
            description={t(langKeys.preset_indoor_climate_description)}
            target={Routes.SettingsPresetsIndoorClimate.location()}
          />
        )}
      </CardBox>
    </MonitoringPresetsBox>
  )
}

const MonitoringPresetsBox = styled.div`
  display: grid;
  gap: ${spacing.XL};
  margin-top: ${spacing.XL};
  container-type: inline-size;
`

const CardBox = styled.div`
  display: grid;
  gap: ${spacing.M};

  @container (${breakpoint.mediumUp}) {
    grid-template-columns: 1fr 1fr;
  }
`

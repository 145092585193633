import { useState } from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components"

import { ApiClientsEmptyState } from "src/components/ApiClients/ApiClientsEmptyState"
import { ApiClientsTable } from "src/components/ApiClients/ApiClientsTable"
import { CreateApiClientDialog } from "src/components/ApiClients/CreateApiClientDialog"
import { DeleteApiClientDialog } from "src/components/ApiClients/DeleteApiClientDialog"
import { EditApiClientDialog } from "src/components/ApiClients/EditApiClientDialog"
import { FeatureBlockerText } from "src/components/FeatureBlockers/FeatureBlockerText"
import { UpgradeBlockerDialog } from "src/components/FeatureBlockers/UpgradeBlockerDialog"
import { Pager } from "src/components/Pager/Pager"
import { useUrlPager } from "src/components/Pager/useUrlPager"
import { useFetchApiClients } from "src/data/apiClients/apiClientQueries"
import { IApiClient } from "src/data/apiClients/apiClientTypes"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { OwnerType } from "src/data/integrations/types/integrationTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useAppLocation } from "src/hooks/useAppLocation"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"
import { MainView } from "src/ui/Layout/MainView"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function ApiClients() {
  // Due to remounting issue which causes flickering we have to use useParams
  // and cannot sent the id down with pathfragment crap
  const { editClientId } = useParams<{ editClientId?: string }>()
  const { t, langKeys } = useTranslate()

  const { limit, offset, setOffset } = useUrlPager()
  const { navigate } = useRouter()
  const location = useAppLocation()
  const { org } = useOrganization()

  const [clientToBeDeleted, setClientToBeDeleted] = useState<IApiClient>()
  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false)

  const fetchApiClients = useFetchApiClients({
    owner_id: org.id,
    owner_type: OwnerType.ORGANIZATION,
    limit,
    offset,
  })
  const apiAccessAvailability = useFeatureAvailability({
    feature: "api_access",
  })

  const openAddDialog =
    location.pathname === Routes.ApiClientsCreate.location().pathname &&
    apiAccessAvailability.available

  const openEditDialog =
    editClientId &&
    location.pathname ===
      Routes.ApiClientsEdit.location(editClientId).pathname &&
    apiAccessAvailability.available

  function handleCreateClientClick() {
    if (!apiAccessAvailability.available) {
      setShowUpgradeDialog(true)
    } else {
      navigate(Routes.ApiClientsCreate.location().pathname)
    }
  }

  function handleEditClientClick(client: IApiClient) {
    if (!apiAccessAvailability.available) {
      setShowUpgradeDialog(true)
    } else {
      navigate(Routes.ApiClientsEdit.location(client.client_id))
    }
  }

  return (
    <Box>
      <MainView
        title={
          <FeatureBlockerText
            isFeatureAvailable={apiAccessAvailability.available}
            hidden={!apiAccessAvailability.ready}
          >
            <MText variant="heading2" marginBottom={spacing.XS}>
              {t(langKeys.api_clients_title)}
            </MText>
          </FeatureBlockerText>
        }
        metaTitle={t(langKeys.api_clients_title)}
        titleBarProps={{
          description: (
            <MText variant="body" color="secondary">
              {t(langKeys.api_clients_body)}{" "}
              <ExternalLink href="https://support.minut.com/en/articles/1653265-write-custom-integrations-with-the-minut-api">
                {t(langKeys.learn_more)}
              </ExternalLink>
            </MText>
          ),
          actionBar: (
            <MButtonLegacy onClick={handleCreateClientClick} size="large">
              {t(langKeys.api_clients_create_client)}
            </MButtonLegacy>
          ),
        }}
      >
        <ApiClientsTable
          clients={fetchApiClients.data?.clients || []}
          onEdit={handleEditClientClick}
          onDelete={(client) => setClientToBeDeleted(client)}
        />
        <Box>
          <Pager
            limit={limit}
            offset={offset}
            setOffset={setOffset}
            totalCount={fetchApiClients.data?.paging.total_count}
          />
        </Box>
        {fetchApiClients.data?.clients.length === 0 && (
          <ApiClientsEmptyState onCreateClientClick={handleCreateClientClick} />
        )}
      </MainView>

      <UpgradeBlockerDialog
        context="api_clients"
        open={showUpgradeDialog}
        onClose={() => setShowUpgradeDialog(false)}
      />

      {openEditDialog && <EditApiClientDialog clientId={editClientId} />}

      {openAddDialog && <CreateApiClientDialog />}

      {clientToBeDeleted && (
        <DeleteApiClientDialog
          client={clientToBeDeleted}
          onClose={() => setClientToBeDeleted(undefined)}
        />
      )}
    </Box>
  )
}

const Box = styled.div`
  margin-top: ${spacing.XL};
`

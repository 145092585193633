import styled from "styled-components"

import { TInternalLinkProps } from "src/ui/Link/InternalLink"
import { MCardInteractive } from "src/ui/MCard/MCardInteractive"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function PresetCard({
  title,
  description,
  icon,
  target,
}: {
  title: string
  description: string
  icon: React.ReactNode
  target: TInternalLinkProps["to"]
}) {
  return (
    <MCardInteractive showHoverEffect linkTo={target}>
      <CardContents>
        {icon}
        <MText variant="heading3">{title}</MText>
        <MText variant="body" color="secondary">
          {description}
        </MText>
      </CardContents>
    </MCardInteractive>
  )
}

export const CardContents = styled.div`
  display: grid;
  gap: ${spacing.XS};
`

import { useState } from "react"
import styled from "styled-components"

import { useAppData } from "src/context/useAppData"
import { useFetchDeviceCount } from "src/data/devices/queries/deviceQueries"
import { useFetchHomesCount } from "src/data/organizations/queries/homeQueries"
import { useFetchMemberCount } from "src/data/organizations/queries/memberQueries"
import { useDeleteOrganization } from "src/data/organizations/queries/organizationQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"
import { gray50 } from "src/ui/colors"
import ConfirmDialog from "src/ui/Dialog/ConfirmDialog"
import { MText } from "src/ui/MText"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"

export function OrganizationDelete({
  id,
  name,
  canDeleteOrg,
  disabled,
}: {
  id: string
  name: string
  canDeleteOrg: boolean
  disabled?: boolean
}) {
  const { t, langKeys } = useTranslate()
  const { navigate } = useRouter()

  const { setActiveOrgId } = useAppData()
  const deleteOrganization = useDeleteOrganization()

  const fetchHomesCount = useFetchHomesCount({ orgId: id })
  const nbrHomes = fetchHomesCount.data

  const fetchMemberCount = useFetchMemberCount({ orgId: id })
  const nbrMembers = fetchMemberCount.data

  const fetchOrgDeviceCount = useFetchDeviceCount({ orgId: id })
  const nbrSensors = fetchOrgDeviceCount.data

  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [teamName, setTeamName] = useState("")

  function handleDelete() {
    deleteOrganization.mutate(
      { orgId: id },
      {
        onSuccess: () => {
          setActiveOrgId(null)
          navigate(Routes.Dashboard.location())
        },
      }
    )
  }

  if (!canDeleteOrg) {
    return null
  }

  return (
    <OrganizationDeleteBox>
      <MText variant="subtitle" marginBottom={spacing.XS}>
        {t(langKeys.organizations_delete_organization)}
      </MText>
      <MText variant="bodyS" color="secondary" marginBottom={spacing.M}>
        {t(langKeys.organizations_delete_organization_description)}
      </MText>

      <MButtonLegacy
        onClick={() => setShowConfirmDelete(true)}
        loading={deleteOrganization.isLoading}
        disabled={disabled}
        variant="outlined"
        emergency
        size="large"
      >
        {t(langKeys.organizations_delete_organization)}
      </MButtonLegacy>

      <ConfirmDialog
        title={t(langKeys.organizations_delete_details_title, { 0: name })}
        confirmLabel={t(langKeys.organizations_delete_organization_action)}
        confirmButtonProps={{
          color: "destructive",
          disabled: teamName !== name,
        }}
        open={showConfirmDelete}
        onClose={() => {
          setShowConfirmDelete(false)
          deleteOrganization.reset()
        }}
        onConfirm={handleDelete}
        loading={deleteOrganization.isLoading}
        error={
          deleteOrganization.isError && t(langKeys.failed_something_went_wrong)
        }
      >
        <DeleteSafeGuard
          orgName={name}
          teamName={teamName}
          onChangeTeamName={setTeamName}
          nbrHomes={nbrHomes}
          nbrMembers={nbrMembers}
          nbrSensors={nbrSensors}
        />
      </ConfirmDialog>
    </OrganizationDeleteBox>
  )
}

const OrganizationDeleteBox = styled.div`
  display: grid;
  max-width: 600px;
  justify-items: start;
`

function DeleteSafeGuard({
  orgName,
  nbrHomes,
  nbrMembers,
  nbrSensors,
  teamName,
  onChangeTeamName,
}: {
  orgName: string | undefined
  teamName: string
  nbrHomes: number | undefined
  nbrMembers: number | undefined
  nbrSensors: number | undefined
  onChangeTeamName: (name: string) => void
}) {
  const { t, langKeys } = useTranslate()

  return (
    <div>
      <div>{t(langKeys.organizations_delete_details_body)}</div>
      <BoxGrid>
        <Box>
          <b>{nbrHomes}</b>
          <div>{t(langKeys.home_plural)}</div>
        </Box>

        <Box>
          <b>{nbrMembers}</b>
          <div>{t(langKeys.members)}</div>
        </Box>

        <Box>
          <b>{nbrSensors}</b>
          <div>{t(langKeys.sensor_plural)}</div>
        </Box>
      </BoxGrid>

      <p>
        {t(langKeys.organizations_delete_organization_confirmation_message)}
      </p>

      <MTextField
        value={teamName}
        onChange={(value) => onChangeTeamName(value)}
        placeholder={orgName}
      />
    </div>
  )
}

const BoxGrid = styled.div`
  margin-top: ${spacing.S};
  display: grid;
  gap: ${spacing.S};
  max-width: 500px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  place-items: center;
  background-color: ${gray50};
  border-radius: 5px;
  padding: ${spacing.S} ${spacing.L};
`

import styled from "styled-components"

import { ApiClients } from "src/components/ApiClients/ApiClients"
import { MonitoringPresets } from "src/components/MonitoringPresets/MonitoringPresets"
import { OrganizationSettings } from "src/components/Organizations/OrganizationEdit/OrganizationSettings"
import { SettingsProfiles } from "src/components/SettingsProfiles/SettingsProfiles"
import { useBackendFlags } from "src/data/flags/useBackendFlags"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { getAccessLogic } from "src/data/user/logic/accessLogic"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { MainView } from "src/ui/Layout/MainView"
import { MBanner } from "src/ui/MBanner/MBanner"
import { ITab } from "src/ui/Tabs/Tab"
import { TabPanel } from "src/ui/Tabs/TabPanel"
import { Tabs } from "src/ui/Tabs/Tabs"

export function GeneralSettings() {
  const { t, langKeys } = useTranslate()
  const { org } = useOrganization()
  const beFlags = useBackendFlags()
  const presets = !!beFlags.noise_profiles_released

  const { hasAdminAccess } = getAccessLogic({
    role: org.user_role,
  })

  const tabs: ITab[] = [
    {
      label: langKeys.monitoring,
      id: Routes.MonitoringSettings.location().pathname,
      view: <SettingsProfiles key="monitoring" />,
      hidden: !!presets,
    },
    {
      label: "Monitoring presets",
      id: Routes.SettingsPresets.location().pathname,
      view: <MonitoringPresets />,
      hidden: !presets,
    },
    {
      label: langKeys.organization,
      id: Routes.OrganizationSettings.location().pathname,
      view: <OrganizationSettings key="team-settings" />,
      hidden: !hasAdminAccess,
    },
    {
      label: "Developer tools",
      id: Routes.ApiClientsSetting.location().pathname,
      view: <ApiClients key="api-clients-settings" />,
    },
  ].filter((t) => !t?.hidden)

  return (
    <MainView title={t(langKeys.settings)}>
      {hasAdminAccess ? (
        <Tabs labels={tabs} hideLabels={tabs.length === 1} usePaths>
          {tabs.map((tab) => (
            <StyledTabPanel key={tab.label}>{tab.view}</StyledTabPanel>
          ))}
        </Tabs>
      ) : (
        <MBanner type="error" size="large">
          Access denied, you do not have permission to access this resource.
          Contact an admininstrator to get permission.
        </MBanner>
      )}
    </MainView>
  )
}

const StyledTabPanel = styled(TabPanel)``

import styled from "styled-components"

import { TSettingContainerOnSaveReturnType } from "src/components/Settings/Setting/SettingContainer"
import { SettingToggle } from "src/components/Settings/Setting/SettingToggle"
import { HREF_MINUT_HELP_SENSOR_SWAP } from "src/constants/hrefs"
import { usePatchOrganization } from "src/data/organizations/queries/organizationQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { divider } from "src/ui/colors"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MBanner } from "src/ui/MBanner/MBanner"
import { spacing } from "src/ui/spacing"

export function SensorSwap({
  orgId,
  enabled,
}: {
  orgId: string
  enabled: boolean
}) {
  const { t, langKeys } = useTranslate()

  const info = (
    <div>
      {t(langKeys.sensor_swap_description)}{" "}
      <ExternalLink href={HREF_MINUT_HELP_SENSOR_SWAP}>
        {t(langKeys.learn_more)}
      </ExternalLink>
    </div>
  )

  const patchOrganization = usePatchOrganization()

  async function handleSensorSwapToggle(): TSettingContainerOnSaveReturnType {
    try {
      await patchOrganization.mutateAsync({
        orgId,
        body: { sensor_swap: { enabled: !enabled } },
      })

      return {
        isSuccess: true,
      }
    } catch (error) {
      return {
        isSuccess: false,
      }
    }
  }

  return (
    <Box>
      <SettingToggle
        title={t(langKeys.sensor_swap)}
        description={info}
        onSave={handleSensorSwapToggle}
        value={enabled}
        hasError={patchOrganization.isError}
        disabled={patchOrganization.isLoading}
      />
      {patchOrganization.isError && (
        <StyledAlert type="error">
          {t(langKeys.failed_something_went_wrong)}
        </StyledAlert>
      )}
    </Box>
  )
}

const Box = styled.div`
  padding-bottom: ${spacing.L};
  border-bottom: 1px solid ${divider};
`

const StyledAlert = styled(MBanner)`
  margin-top: ${spacing.L};
`
